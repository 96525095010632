<template>
    <section class="section">
        <h2>デフォルト設定</h2>

        <inline-loader v-if="account_loading"></inline-loader>
        <form class="form" @submit.prevent="saveAccount" v-else>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="form-label">リードタイム</label>
                    <div class="input-group">
                        <input type="number" class="form-control" v-model="account.default_lead_time" required>
                        <div class="input-group-text">日</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-label">サブコンディション</label>
                    <select class="form-control" v-model="account.default_sub_condition" required>
                        <option :value="null">--選択--</option>
                        <option value="11">新品</option>
                        <option value="21">新品同様</option>
                        <option value="22">非常に良い</option>
                        <option value="23">良い</option>
                        <option value="24">可</option>
                        <option value="25">不可</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label class="form-label">除外支払方法</label>
                    <select class="form-control" v-model="account.default_payment_type_exclusion" required>
                        <option :value="null">--選択--</option>
                        <option value="0">除外なし</option>
                        <option value="1">代引除外</option>
                        <option value="2">コンビニ除外</option>
                        <option value="3">代引・コンビニ除外</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="form-label">利益率</label>
                    <div class="input-group">
                        <input type="number" class="form-control" step="0.1" v-model="account.default_profit_ratio">
                        <div class="input-group-text">％</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-label">送料</label>
                    <div class="input-group">
                        <input type="number" class="form-control" v-model="account.default_shipping_fee">
                        <div class="input-group-text">円</div>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary">更新</button>
        </form>
    </section>

    <section class="section">
        <h2>コンディションノート テンプレート設定</h2>

        <inline-loader v-if="note_loading"></inline-loader>
        <template v-else>
            <form class="row mb-3" v-for="note of template_notes" :key="note.template_note_id" @submit.prevent="editNote(note)">
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="note.template_name">
                </div>
                <div class="col-md-16">
                    <textarea class="form-control" rows="5" v-model="note.template_content"></textarea>
                </div>
                <div class="col-md-2">
                    <div class="mb-3"><button type="submit" class="btn btn-primary">更新</button></div>
                    <div class=""><button type="button" @click="removeNote(note)" class="btn btn-outline-danger">削除</button></div>
                </div>
            </form>
        </template>
        <form class="row" @submit.prevent="addNote(note)">
            <div class="col-md-6">
                <input type="text" class="form-control" v-model="new_note.template_name" placeholder="テンプレート名">
            </div>
            <div class="col-md-16">
                <textarea class="form-control" rows="5" v-model="new_note.template_content"></textarea>
            </div>
            <div class="col-md-2">
                <button type="submit" class="btn btn-primary">登録</button>
            </div>
        </form>
    </section>
</template>

<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';

export default {
    name: 'PageSettingInventory',
    components: {
        InlineLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            account_loading: true,
            account: null,
            note_loading: true,
            template_notes: [],
            new_note: {
                template_name: null,
                template_content: null,
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.account_loading = true;
            this.$http.get('/me/account')
            .then((response) => {
                this.account = response.data;
            })
            .finally(() => {
                this.account_loading = false;
            });

            this.note_loading = true;
            this.$http.get('/template-notes')
            .then((response) => {
                this.template_notes = response.data;
            })
            .finally(() => {
                this.note_loading = false;
            });
        },
        saveAccount() {
            this.startScreenLoading();
            this.$http.put('/me/account', this.account)
            .then((response) => {
                this.account = response.data;
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        addNote() {
            this.startScreenLoading();
            this.$http.post('/template-notes', this.new_note)
            .then((response) => {
                this.template_notes.push(response.data);
                this.new_note = {
                    template_name: null,
                    template_content: null,
                };
                this.showMessage('登録しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        editNote(note) {
            this.startScreenLoading();
            this.$http.put('/template-notes/' + note.template_note_id, note)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        removeNote(note) {
            this.startScreenLoading();
            this.$http.delete('/template-notes/' + note.template_note_id)
            .then(() => {
                this.template_notes = this.template_notes.filter((row) => row.template_note_id !== note.template_note_id);
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
